import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { RouterProps, withRouter } from 'react-router';

// const Video = styled.video`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   min-width: 100%;
//   min-height: 100%;
//   width: auto;
//   height: auto;
//   z-index: -100;
//   transform: translateX(-50%) translateY(-50%);
//   transition: 1s opacity;
// `;

const Background = styled.div`
  background: url('./images/sob.png') no-repeat center center;
  background-size: cover;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  transition: 1s opacity;
`;

const Title = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 64px);
  margin-top: 64px;
  top: 0;
  z-index: 1;

  & > * {
    color: #fff;
    font-weight: 700;
  }

  h1 {
    font-size: 25px;
    font-weight: 500;
    margin: 0;
  }

  h2 {
    font-size: 50px;
    margin: 0;
  }
`;

const Home = ({ history }: RouterProps) => (
  <>
    <Title>
      <h1>SAM-OU-HELL</h1>
      <h2>SOB</h2>
      <Button ghost={true} onClick={() => history.push('/video')}>
        zobrazit
      </Button>
    </Title>
    <Background />
    {/*<Video*/}
    {/*  poster="./video/web1.jpg"*/}
    {/*  playsInline={true}*/}
    {/*  autoPlay={true}*/}
    {/*  muted={true}*/}
    {/*  loop={true}*/}
    {/*>*/}
    {/*  <source src="./video/web1.webm" type="video/webm" />*/}
    {/*  <source src="./video/web1.mp4" type="video/mp4" />*/}
    {/*</Video>*/}
  </>
);

export default withRouter(Home);
