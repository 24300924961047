import React from 'react';
import { Layout, Icon } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  
  h1 {
    font-size: 1.5em;
  }
`;

const ContactItem = styled.div`
  margin-right: 10px;
  font-size: 16px;

  & > span {
    margin-left: 10px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  font-size: 16px;
`;

const Contact = () => (
  <Layout.Content style={{ padding: '10px 10px', background: '#fff' }}>
    <Wrapper>
      <h1>Management a booking koncertů</h1>
      <h2>Josef Nejezchleba</h2>
      <div>
        <ContactItem>
          <Icon type="phone" theme="twoTone" />
          <span>+420 732 505 165</span>
        </ContactItem>
        <ContactItem>
          <Icon type="mail" theme="twoTone" />
          <span>pepa@samouhell.cz</span>
        </ContactItem>
      </div>
      <ButtonWrapper>
        <a href="./images/stageplan.png" target="_blank">
          stáhnout stageplan
        </a>
      </ButtonWrapper>
    </Wrapper>
  </Layout.Content>
);

export default Contact;
