import React from 'react';
import MusicPlayer from 'react-responsive-music-player';
import { Layout } from 'antd';
import styled from 'styled-components';

const playlist = [
  {
    url: './audio/generaceg.mp3',
    cover: './images/generaceg.png',
    title: 'Generace G',
    artist: ['SAM-OU-HELL'],
  },
  {
    url: './audio/panenka.mp3',
    cover: './images/generaceg.png',
    title: 'Panenka',
    artist: ['SAM-OU-HELL'],
  },
];

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
`;

const Discography = () => (
  <Layout.Content style={{ padding: '10px 10px', background: '#fff' }}>
    <Wrapper>
      <MusicPlayer mode="vertical" playlist={playlist} style={{ maxWidth: 'none' }} />
    </Wrapper>
  </Layout.Content>
);

export default Discography;
