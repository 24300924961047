import React from 'react';
import styled from 'styled-components';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Icon, Layout, Menu } from 'antd';

const Logo = styled.div`
  ${(props: { small: boolean }) => `
      background-image: url('/logo${props.small ? '-small' : ''}.png');
      background-size: contain;
      background-repeat: no-repeat;
      height: ${props.small ? '30px' : '20px'};
      width: ${props.small ? '30px' : '120px'};
      cursor: pointer;
  `};
`;

interface IProps extends RouteComponentProps {
  isBroken: boolean;
  isCollapsed: boolean;
  onClick: () => void;
}

const Navigation = ({ history, isBroken, isCollapsed, onClick }: IProps) => {
  return (
    <Layout.Header
      style={{
        background: '#fff',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Logo onClick={() => history.push('/')} small={isBroken} />
      {isBroken && (
        <Icon
          style={{
            fontSize: '1.2em',
            marginRight: isCollapsed ? '0' : '200px',
          }}
          type={isCollapsed ? 'menu' : 'close'}
          onClick={onClick}
        />
      )}
      {!isBroken && (
        <Menu mode="horizontal">
          <Menu.Item>
            <Link to="/koncerty">Koncerty</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/hudba">Hudba</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/video">Video</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/kontakt">Kontakt</Link>
          </Menu.Item>
        </Menu>
      )}
    </Layout.Header>
  );
};

export default withRouter(Navigation);
