import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

const Video = () => (
  <Layout.Content style={{ padding: '10px 10px', background: '#fff' }}>
    <Wrapper>
      <iframe
        title="soh-sob"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/6p-tkEud4rY"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Wrapper>
  </Layout.Content>
);

export default Video;
