import { Col, Row, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { ICalendarItem } from '../Concerts';

interface IProps {
  item: ICalendarItem;
}

const colStyle = { height: '32px', display: 'flex', alignItems: 'center' };

const Concert = ({ item }: IProps) => (
  <Row gutter={16}>
    <Col span={8} style={{ fontWeight: 700, ...colStyle }}>
      {moment(item.start.dateTime).format('D. M. YYYY')}
    </Col>
    <Col span={8} style={colStyle}>
      <Typography.Paragraph ellipsis={true} style={{ padding: 0, margin: 0 }}>
        {item.summary}
      </Typography.Paragraph>
    </Col>
    <Col span={6} style={colStyle}>
      {moment(item.start.dateTime).format('HH:mm')}
    </Col>
  </Row>
);

export default Concert;
