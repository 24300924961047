import React from 'react';
import axios from 'axios';
import { Layout, Spin } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

const credentialsUrl =
  'https://graph.facebook.com/oauth/access_token?client_id=569506679922652&client_secret=e15ce158cfcfc825f807d183cb1b1921&grant_type=client_credentials';

const getPageIdUrl = (accessToken: string) =>
  `https://graph.facebook.com/samouhell?access_token=${accessToken}`;

const getPagePostsUrl = (pageId: string, accessToken: string) =>
  `https://graph.facebook.com/${pageId}/posts?fields=picture,message,created_time,link&access_token=${accessToken}`;

const NewsFeed = styled.div`
  font-size: 0;
  line-height: 0;
  background-color: #eaeaea;
  padding: 0 0 50px 0;
  min-height: 100%;
`;

const NewsFeedWrap = styled.div`
  padding: 50px 0 0 0;
`;

const NewsItem = styled.a`
  position: relative;
  font-size: 18px;
  line-height: 1.5;
  display: block;
  text-align: left;
  background-color: #fff;
  border-radius: 3px;
  transition: all 200ms ease;
  padding: 20px 20px 20px 17px;

  :nth-of-type(2n + 1) {
    border-right: 3px solid #fff;
    left: calc(50% + 40px);
    width: calc(50% - 60px);
  }

  :nth-of-type(2n + 2) {
    border-left: 3px solid #fff;
    left: 30px;
    width: calc(50% - 60px);
  }

  ::after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    display: block;
    top: 14px;
    border: 3px solid #eaeaea;
    background-color: #69f5b8;
    border-radius: 100%;
  }

  :nth-of-type(2n + 1)::after {
    left: -50px;
  }

  :nth-of-type(2n + 2)::after {
    right: -40px;
  }

  :nth-of-type(6n + 1)::after {
    background-color: #f56969;
  }
  :nth-of-type(6n + 2)::after {
    background-color: #f569c2;
  }
  :nth-of-type(6n + 3)::after {
    background-color: #69edf5;
  }
  :nth-of-type(6n + 4)::after {
    background-color: #f59e69;
  }
  :nth-of-type(6n + 5)::after {
    background-color: #bff569;
  }
  :nth-of-type(6n + 6)::after {
    background-color: #69f5b8;
  }
  :nth-of-type(6n + 1):hover {
    border-color: #f56969;
  }
  :nth-of-type(6n + 2):hover {
    border-color: #f569c2;
  }
  :nth-of-type(6n + 3):hover {
    border-color: #69edf5;
  }
  :nth-of-type(6n + 4):hover {
    border-color: #f59e69;
  }
  :nth-of-type(6n + 5):hover {
    border-color: #bff569;
  }
  :nth-of-type(6n + 6):hover {
    border-color: #69f5b8;
  }

  img {
    max-width: 100%;
    margin-right: 20px;
    float: left;
    display: block;
  }
`;

const Clear = styled.div`
  clear: both;
`;
const Timeline = styled.div`
  position: fixed;
  width: 2px;
  height: 100%;
  left: 50%;
  margin-left: -1px;
  background-color: #999999;
`;

const NewsDate = styled.div`
  color: #808080;
  text-align: right;
  display: block;
  padding: 10px 0 0 0;
  font-size: 16px;
`;
const SpinContainer = styled.div`
  background-color: #eaeaea;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ICredentialsResponse {
  access_token: string;
}

class News extends React.Component<any, any> {
  state = {
    isLoading: true,
    posts: [],
  };

  componentDidMount = async () => {
    const credentials = await axios.get<ICredentialsResponse>(credentialsUrl);
    const page = await axios.get<any>(
      getPageIdUrl(credentials.data.access_token),
    );
    const posts = await axios.get<any>(
      getPagePostsUrl(page.data.id, credentials.data.access_token),
    );

    console.log(posts.data);
    this.setState({ isLoading: false, posts: posts.data.data });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Layout.Content style={{ background: '#fff' }}>
        {isLoading && (
          <SpinContainer>
            <Spin size="large" />
          </SpinContainer>
        )}
        {!isLoading && (
          <NewsFeed>
            <Timeline />
            <NewsFeedWrap>
              {this.state.posts.map((post: any) => (
                <NewsItem key={post.id} target="_blank" href={post.link}>
                  {post.picture && (
                    <img alt="obrázek příspěvku" src={post.picture} />
                  )}
                  <p>{post.message}</p>
                  <Clear />
                  <NewsDate>{moment(post.created_time).format('DD.MM.YYYY')}</NewsDate>
                </NewsItem>
              ))}
            </NewsFeedWrap>
          </NewsFeed>
        )}
      </Layout.Content>
    );
  }
}

export default News;
