import React from 'react';
import Navigation from './Navigation';
import { HashRouter, Link, Route } from 'react-router-dom';
import Home from './Home';
import News from './News';
import Discography from './Discography';
import Concerts from './Concerts';
import { Layout, Menu } from 'antd';
import Contact from './Contact';
import Video from './Video';
import { useBoolean } from './hooks';

const App = () => {
  const isBroken = useBoolean(false);
  const isCollapsed = useBoolean(true);

  return (
    <HashRouter>
      <Layout>
        <Layout>
          <Navigation
            isCollapsed={isCollapsed.value}
            isBroken={isBroken.value}
            onClick={isCollapsed.toggle}
          />
          <Layout.Content>
            <Route path="/" exact component={Home} />
            <Route path="/novinky" component={News} />
            <Route path="/hudba" component={Discography} />
            <Route path="/koncerty" component={Concerts} />
            <Route path="/kontakt" component={Contact} />
            <Route path="/video" component={Video} />
          </Layout.Content>
        </Layout>
        <Layout.Sider
          style={{
            position: 'absolute',
            right: 0,
            zIndex: 999,
            height: '100vh',
          }}
          trigger={null}
          breakpoint="sm"
          collapsedWidth="0"
          collapsed={isCollapsed.value}
          onBreakpoint={broken => {
            isBroken.setValue(broken);
            isCollapsed.setValue(true);
          }}
        >
          <Menu theme="dark" mode="inline">
            <Menu.Item>
              <Link to="/koncerty">Koncerty</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/hudba">Hudba</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/video">Video</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/kontakt">Kontakt</Link>
            </Menu.Item>
          </Menu>
        </Layout.Sider>
      </Layout>
    </HashRouter>
  );
};

export default App;
