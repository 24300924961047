import React from 'react';
import axios from 'axios';
import { Collapse, Empty, Layout, Spin } from 'antd';
import styled from 'styled-components';
import Concert from './components/Concert';

export interface ICalendarItem {
  id: string;
  summary: string;
  description?: string;
  location?: string;
  start: {
    dateTime: string;
  };
  end: {
    dateTime: string;
  };
}

const HoverBackground = styled.div`
  .ant-collapse-item:hover {
    background: linear-gradient(
      135deg,
      rgba(245, 105, 105, 0.2) 0%,
      rgba(245, 105, 194, 0.2) 19%,
      rgba(105, 237, 245, 0.2) 38%,
      rgba(245, 158, 105, 0.2) 60%,
      rgba(191, 245, 105, 0.2) 80%,
      rgba(105, 245, 184, 0.2) 100%
    );
  }
`;

interface ICalendarResponse {
  items: Array<ICalendarItem>;
}

class Concerts extends React.Component<
  {},
  { concerts: ICalendarItem[]; isLoading: boolean }
> {
  state = {
    isLoading: true,
    concerts: [],
  };

  componentDidMount() {
    const url =
      'https://www.googleapis.com/calendar/v3/calendars/v9l77eljge2pk4fb06es09avcg@group.calendar.google.com/events?key=AIzaSyDbe5ChU9j3xxgCy_o2xvfp8OQUe3rV9Bg&orderBy=starttime&singleEvents=true&timeMin=' +
      new Date().toISOString();
    axios.get<ICalendarResponse>(url).then(res => {
      this.setState({ isLoading: false, concerts: res.data.items });
    });
  }

  render() {
    const { concerts, isLoading } = this.state;
    return (
      <Layout.Content style={{ padding: '10px 10px', background: '#fff' }}>
        <HoverBackground>
          <Spin spinning={isLoading}>
            <Collapse bordered={false}>
              {concerts.map((item: ICalendarItem) => (
                <Collapse.Panel header={<Concert item={item} />} key={item.id}>
                  {item.description}
                </Collapse.Panel>
              ))}
            </Collapse>
            {!concerts.length && !isLoading && (
              <Empty description="Žádné nadcházející koncerty." />
            )}
          </Spin>
        </HoverBackground>
      </Layout.Content>
    );
  }
}

export default Concerts;
