import { SyntheticEvent, useCallback, useState } from 'react';

export const useBoolean = (initial: boolean) => {
  const [value, setValue] = useState(initial);

  return {
    setFalse: useCallback((event?: SyntheticEvent) => {
      if (event) {
        event.stopPropagation();
      }
      setValue(false);
    }, []),
    setTrue: useCallback((event?: SyntheticEvent) => {
      if (event) {
        event.stopPropagation();
      }
      setValue(true);
    }, []),
    setValue,
    toggle: useCallback(() => setValue((v: boolean) => !v), []),
    value,
  };
};
